import React from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import ASSETS from 'assets';
import NotFound from './NotFound';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';

const Companies = () => {
  const navigate = useNavigate();

  const { data, loading } = useSelector((state: RootState) => state.search);

  const handleNavigate = (id: string) => {
    navigate(`/other-employer/${id}`);
  };

  return (
    <Container className=' w-full rounded-lg'>
      {loading ? (
        <div className='flex justify-center py-10'>
          <ClipLoader size={70} color={theme.colors.primary} />
        </div>
      ) : (
        <>
          {data?.company.length === 0 ? (
            <NotFound />
          ) : (
            <div className='flex flex-col 2xl:gap-4 gap-3 w-full max-w-[821px] mx-auto mt-[22px] pb-4'>
              {data?.company?.map(c => (
                <div
                  key={c.id}
                  className='flex items-center rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'
                  onClick={() => handleNavigate(c.id)}
                >
                  <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                    <img src={c.company_logo || ASSETS.preview} alt='' />
                  </div>
                  <div>
                    <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                      {c.name}
                    </p>
                    <p className='2xl:text-lg text-base font-medium leading-6'>
                      {c.location}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default Companies;

const Container = styled.div`
  /* max-width: 821px; */
  width: 100%;
  /* margin-top: 22px; */
  /* overflow: hidden; */
  margin-inline: auto;
  /* box-shadow: 0px 5.4px 18.92px 0px #0000001a; */
`;

import React, { FC, useEffect, useState } from 'react';

import { useFormik } from 'formik';
import { object, string } from 'yup';

import DialogLayout from 'Dialogs/DialogLayout';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import ASSETS from 'assets';

const schema = object({
  first_name: string().required('Required field').label('First Name'),
  last_name: string().required('Required field').label('Last Name'),
  job_title: string().required('Required field').label('Job Title'),
  location: string().required('Required field').label('Location'),
});

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProfile: FC<IProps> = ({ open, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.user);

  const [image, setImage] = useState<File | null | string>(null);
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      job_title: '',
      location: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      if (image && typeof image !== 'string') {
        const formData = new FormData();
        formData.append('profile', image);
        dispatch.user.handleUpdateProfile({
          values,
          afterUpdate: handleClose,
          profile: formData,
        });
      } else {
        dispatch.user.handleUpdateProfile({ values, afterUpdate: handleClose });
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (user) {
      formik.setValues({
        first_name: user.first_name,
        last_name: user.last_name,
        job_title: user.job_title || '',
        location: user.location || '',
      });
      setImage(user.image_url);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[546px] max-w-[500px] bg-grey-100 rounded-[15px] 2xl:p-6 p-5'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <div className='2xl:w-[180px] w-[140px] mx-auto'>
          <ImagePicker image={image} setImage={setImage} />
        </div>
        <form
          onSubmit={e => e.preventDefault()}
          className='flex flex-col 2xl:gap-6 gap-4 w-full mt-5'
        >
          <TextField
            label=''
            placeholder='Name*'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            name='first_name'
            error={
              formik.touched.first_name && formik.errors.first_name
                ? formik.errors.first_name
                : ''
            }
          />
          <TextField
            label=''
            placeholder='Name*'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            name='last_name'
            error={
              formik.touched.last_name && formik.errors.last_name
                ? formik.errors.last_name
                : ''
            }
          />
          <TextField
            label=''
            placeholder='Job Title*'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.job_title}
            name='job_title'
            error={
              formik.touched.job_title && formik.errors.job_title
                ? formik.errors.job_title
                : ''
            }
          />
          <TextField
            label=''
            placeholder='Location*'
            icon={ASSETS.location}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.location}
            name='location'
            error={
              formik.touched.location && formik.errors.location
                ? formik.errors.location
                : ''
            }
          />
          <Button
            label='Save Changes'
            className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg 2xl:mt-[30px] mt-5'
            onClick={() => formik.handleSubmit()}
            loading={loading}
          />
        </form>
      </div>
    </DialogLayout>
  );
};

export default EditProfile;

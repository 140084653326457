import { FC, useEffect } from 'react';

import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from './theme/GlobalStyles';

import { useDispatch } from 'react-redux';
import { Dispatch } from './store';

import Router from 'router/index';

import theme from './theme';

import 'react-toastify/dist/ReactToastify.css';
import { CONSTANTS } from 'app.config';
import { getLanguage } from 'utils';
// import ResponsiveHelper from 'components/ResponsiveHelper/ResponsiveHelper';

const App: FC = () => {
  const dispatch = useDispatch<Dispatch>();

  const token = localStorage.getItem(CONSTANTS.TOKEN);

  useEffect(() => {
    if (token) {
      dispatch.auth.handleGetCurrentUser();
    }

    console.log(getLanguage());
    // eslint-disable-next-line
  }, [token]);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyles />
        <Router />
      </BrowserRouter>
      <ToastContainer />
      {/* <ResponsiveHelper /> */}
    </ThemeProvider>
  );
};

export default App;

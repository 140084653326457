import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import styled from 'styled-components';
import ASSETS from 'assets';
import NotFound from './NotFound';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';

const Jobs = () => {
  const { data, loading } = useSelector((state: RootState) => state.search);

  return (
    <Container>
      {/* <Tabs className='flex items-center gap-[7px] w-full justify-center'>
        {jobs.map(i => (
          <div
            key={i}
            className={classNames(
              'tab h-10 rounded-3xl  min-w-[100px] flex items-center justify-center cursor-pointer border border-grey-600 transition px-4',
              selected === i ? 'border-0 bg-primary text-white' : 'bg-white'
            )}
            onClick={() => handleTab(i)}
          >
            <p className='text-sm font-medium text-inherit'>{i}</p>
          </div>
        ))}
      </Tabs> */}

      {loading ? (
        <div className='flex w-full justify-center py-10'>
          <ClipLoader size={70} color={theme.colors.primary} />
        </div>
      ) : (
        <>
          {data?.jobs.length === 0 ? (
            <div className='flex justify-center w-full'>
              <NotFound />
            </div>
          ) : (
            <JobsSection className='pt-[14px]'>
              {data?.jobs.map(j => (
                <div
                  key={j.company_id}
                  className='flex items-center rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'
                >
                  <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                    <img src={j.company_logo || ASSETS.preview} alt='' />
                  </div>
                  <div>
                    <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                      {j.name}
                    </p>
                    <p className='2xl:text-lg text-base font-medium leading-6'>
                      {j.title}
                    </p>
                    <p className='2xl:text-base text-sm'>{j.location}</p>
                  </div>
                </div>
              ))}
            </JobsSection>
          )}
        </>
      )}
    </Container>
  );
};

export default Jobs;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  padding-top: 0px;
  align-items: flex-start;
  flex-direction: column;
`;

// const Tabs = styled.div``;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

// const Job = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 29px;
// `;

import ASSETS from 'assets';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';
import { IApplicationData } from 'types/interfaces';
import { convertNumberToK } from 'utils';

interface IProps {
  data: IApplicationData | null;
  setApplicationId: React.Dispatch<React.SetStateAction<string>>;
}

const SelectedApplication: FC<IProps> = ({ data, setApplicationId }) => {
  return (
    <>
      {data ? (
        <div className='h-full'>
          <p className='2xl:text-xl text-lg font-bold 2xl:mt-9 mt-6'>
            <span
              className='cursor-pointer'
              onClick={() => setApplicationId('')}
            >
              Received Applications -&gt;
            </span>{' '}
            <Link to={`/other-job-seeker/${data.user_id}`}>
              {data?.user?.first_name} {data?.user?.last_name}
            </Link>
          </p>
          {data?.cv_url && (
            <div className='attachment mt-4'>
              <p className='2xl:text-lg text-base font-medium leading-7'>
                Resume
              </p>
              <a href={data.cv_url} download target='_blank' rel='noreferrer'>
                <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
                  <img
                    src={
                      data?.cv_type === 'application/pdf'
                        ? ASSETS.pdf
                        : ASSETS.word
                    }
                    className='w-6 2xl:w-auto'
                    alt=''
                  />
                  <p className='text-sm font-medium'>
                    {data?.user_name} ({data?.user.job_title}).
                    {data?.cv_type === 'application/pdf' ? 'pdf' : 'doc'}
                  </p>
                </div>
              </a>
            </div>
          )}
          {data?.cover_url && (
            <div className='attachment mt-[11px]'>
              <p className='2xl:text-lg text-base font-medium leading-7'>
                Cover Letter/ Attachment
              </p>
              <a
                href={data.cover_url}
                download
                target='_blank'
                rel='noreferrer'
              >
                <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
                  <img
                    src={
                      data?.cover_type === 'application/pdf'
                        ? ASSETS.pdf
                        : ASSETS.word
                    }
                    className='w-6 2xl:w-auto'
                    alt=''
                  />
                  <p className='text-sm font-medium'>
                    {data?.user_name} ({data?.user.job_title}).
                    {data?.cover_type === 'application/pdf' ? 'pdf' : 'doc'}
                  </p>
                </div>
              </a>
            </div>
          )}
          <div className='flex items-center justify-between mt-6'>
            <p className='text-base font-medium'>Expected Salary Range</p>
            <p className='text-base font-medium w-[12ch]'>
              ${convertNumberToK(data?.salary_range_from || 0)}-$
              {convertNumberToK(data?.salary_range_to || 0)}
            </p>
          </div>
          <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
          <div className='flex flex-col gap-[18px]'>
            <p className='text-base font-medium leading-5'>
              Work Authorization
            </p>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                Are you authorized to work in the United States?
              </p>
              <p className='text-base font-medium leading-5 w-[12ch]'>
                {data?.application_detail.isUSAuthorized}
              </p>
            </div>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                Do you now or in the future require a working visa sponsorship?
              </p>
              <p className='text-base font-medium leading-5 w-[12ch]'>
                {data?.application_detail.isSponsorWorkVisa}
              </p>
            </div>
          </div>
          <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
          <div className='flex flex-col gap-[18px]'>
            <p className='text-base font-medium leading-5'>
              Diversty & Inclusion
            </p>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                Do you have any disability?
              </p>
              <p className='text-base font-medium leading-5 w-[12ch]'>
                {data?.application_detail.isDisability}
              </p>
            </div>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                Do you identify as a vetaran?
              </p>
              <p className='text-base font-medium leading-5 w-[12ch]'>
                {data?.application_detail.isVeteran}
              </p>
            </div>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                What is your racial background?
              </p>
              <p className='text-base font-medium leading-5 w-[12ch]'>
                {data?.application_detail.isRacialBackground}
              </p>
            </div>
          </div>
          <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
          <div className='flex flex-col gap-[18px]'>
            <p className='text-base font-medium leading-5 '>
              Employment History
            </p>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                Have you previously worked for our company?
              </p>
              <p className='text-base font-medium leading-5  w-[12ch]'>
                {data?.application_detail.isFormerEmployee}
              </p>
            </div>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                Have you worked for any affiliate companies?
              </p>
              <p className='text-base font-medium leading-5  w-[12ch]'>
                {data?.application_detail.isAffiliateCompany}
              </p>
            </div>
          </div>
          <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
          <div className='flex flex-col gap-[18px]'>
            <p className='text-base font-medium leading-5 '>Referred By</p>
            <div className='flex justify-between items-center'>
              <p className='text-base font-medium leading-5'>
                Were you referred by someone from our company?
              </p>
              <p className='text-base font-medium leading-5  w-[12ch]'>
                {data?.application_detail.isReferred}
              </p>
            </div>
            {data?.application_detail.is_referral_name && (
              <div className='flex justify-between items-center'>
                <p className='text-base font-medium leading-5'>Referrer name</p>
                <p className='text-base font-medium leading-5 w-[12ch]'>
                  {data?.application_detail.is_referral_name}
                </p>
              </div>
            )}
            {data?.application_detail.is_referral_email && (
              <div className='flex justify-between items-center'>
                <p className='text-base font-medium leading-5'>
                  Referrer email
                </p>
                <p className='text-base font-medium leading-5  w-[12ch]'>
                  {data?.application_detail.is_referral_email}
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='w-full py-20 flex items-center justify-center '>
          <ClipLoader size={50} color={theme.colors.primary} />
        </div>
      )}
    </>
  );
};

export default SelectedApplication;

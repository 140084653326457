import React, { FC, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import ASSETS from 'assets';
import { IReceivedJobs } from 'types/interfaces';
// import ClickAwayListener from 'react-click-away-listener';
import NotFound from './NotFound';
import { t } from 'i18next';
// import { useNavigate } from 'react-router-dom';

dayjs.extend(relativeTime);

interface IProps {
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}

interface AccordionProps {
  jobs: IReceivedJobs[];
  onClick: (val: string) => void;
}

const Accordion: FC<AccordionProps> = ({ jobs, onClick }) => {
  const panel = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleAccordion = () => {
    if (panel.current) {
      if (panel.current.style.maxHeight > '0px') {
        panel.current.style.maxHeight = '0px';
        panel.current.classList.remove('open');
        imgRef.current!.style.transform = 'rotate(90deg)';
        panel.current.style.overflowY = 'hidden';
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
        panel.current.classList.add('open');
        imgRef.current!.style.transform = 'rotate(-90deg)';
      }
    }
  };

  // eslint-disable-next-line
  const handleCloseAccordion = () => {
    if (panel.current) {
      panel.current.style.maxHeight = '0px';
      panel.current.classList.remove('open');
      imgRef.current!.style.transform = 'rotate(90deg)';
      panel.current.style.overflowY = 'hidden';
    }
  };

  return (
    // <ClickAwayListener onClickAway={handleCloseAccordion}>
    <div className='w-full'>
      <div
        className='flex items-center 2xl:gap-[29px] gap-4 rounded-lg shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white cursor-pointer relative'
        onClick={handleAccordion}
      >
        <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
          <img src={jobs[0].m_company_logo || ASSETS.preview} alt='' />
        </div>
        <div>
          <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
            {jobs[0].m_company_name}
          </p>
          <p className='2xl:text-lg text-base font-medium leading-6'>
            {jobs[0].m_job_title}
          </p>
          <p className='2xl:text-base text-sm'>
            Received Applications: ({jobs.length})
          </p>
        </div>
        <img
          src={ASSETS.chevronRight}
          className='w-6 ml-auto overflow-hidden duration-300 transition-all rotate-90'
          ref={imgRef}
          alt=''
        />
      </div>
      <div
        className='page max-h-0 overflow-hidden transition-all duration-300'
        ref={panel}
      >
        <div className='pt-8 flex flex-col gap-2 '>
          {jobs.map(job => (
            <div
              className='flex items-center rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white hover:bg-[#90BDEA30] cursor-pointer'
              onClick={() => onClick(job.application_id)}
            >
              <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                <img src={job.image_url || ASSETS.preview} alt='' />
              </div>
              <div>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  {job.first_name} {job.last_name}
                </p>
                <p className='2xl:text-lg text-base font-medium leading-6'>
                  {job.job_title}
                </p>
                <p className='2xl:text-base text-sm'>
                  {dayjs(job.created_at).fromNow()}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    // </ClickAwayListener>
  );
};

const ReceivedJobApp: FC<IProps> = ({ setSelected }) => {
  const dispatch = useDispatch<Dispatch>();
  const { receivedJobs, applications } = useSelector(
    (state: RootState) => state.myjobs
  );

  const handleSelect = (val: string) => setSelected(val);

  useEffect(() => {
    dispatch.myjobs.handleGetReceivedJobs();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='h-full flex flex-col'>
      <div className='2xl:pt-[33px] pt-5'>
        <div className='flex flex-col gap-3'>
          {applications === 0 && (
            <NotFound
              icon={ASSETS.saved}
              message={t("You haven't received any application")}
            />
          )}
          {receivedJobs &&
            Object.keys(receivedJobs)?.map(key => (
              <Accordion
                key={key}
                jobs={receivedJobs[key]}
                onClick={handleSelect}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ReceivedJobApp;

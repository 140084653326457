import React from 'react';

import { Routes, Route } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout/AuthLayout';
import Signup from 'pages/Signup/Signup';
import Signin from 'pages/Signin/Signin';
import Phone from 'pages/Phone/Phone';
import ResetPassword from 'pages/ResetPassword/ResetPassword';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import Explore from 'pages/Explore/Explore';
import SearchFinds from 'pages/SearchFinds/SearchFinds';
import Myjobs from 'pages/MyJobs/Myjobs';
import Notifications from 'pages/Notifications/Notifications';
import Following from 'pages/Following/Following';
import Chat from 'pages/Chat/Chat';
import Profile from 'pages/Profile/Profile';
import ProfileAndLocation from 'pages/MakeProfile/ProfileAndLocation';
import IntroVideo from 'pages/MakeProfile/IntroVideo';
import UploadDocuments from 'pages/MakeProfile/UploadDocuments';
import ApplyUploadDocuments from 'pages/ApplyJob/UploadDocuments/UploadDocuments';
import Education from 'pages/MakeProfile/Education';
import CreateVideo from 'pages/IntroVideo/CreateVideo/CreateVideo';
import Main from 'pages/Main/Main';
import UploadOrRecord from 'pages/IntroVideo/UploadOrRecord/UploadOrRecord';
import UserProfile from 'pages/UserProfile/UserProfile';
import Report from 'pages/Report/Report';
import OtherEmployerProfile from 'pages/OtherEmployerProfile/OtherEmployerProfile';
import Questions from 'pages/ApplyJob/Questions/Questions';
import SaleryRange from 'pages/ApplyJob/SaleryRange/SaleryRange';
import RecordVideo from 'pages/IntroVideo/RecordVideo/RecordVideo';
import Settings from 'pages/Settings/Settings';
import SearchPreferences from 'pages/SearchPrefernces/SearchPreferences';
import MergeVideos from 'pages/IntroVideo/MergeVideos/MergeVideos';
import ApplicationSubmit from 'pages/ApplicationSubmit/ApplicationSubmit';
import CreateCompany from 'pages/CreateCompany/CreateCompany';
import CreateJob from 'pages/CreateJob/CreateJob';
import SelectCompany from 'pages/SelectCompany/SelectCompany';
import JobQuestions from 'pages/CreateJob/JobQuestions';
import Schedular from 'pages/Schedular/Schedular';
import CompanyProfile from 'pages/CompanyProfile/CompanyProfile';
import Premium from 'pages/Premium/Premium';
import VideoMerge from 'pages/VideoMerge/VideoMerge';
import VideoTrimmer from 'pages/VideoTrimmer/VideoTrimmer';
import RedirectOutlet from './RedirectOutlet/RedirectOutlet';
import PrivateOutlet from './PrivateOutlet/PrivateOutlet';

const Index = () => {
  return (
    <Routes>
      <Route path='/' element={<RedirectOutlet />}>
        <Route path='' element={<AuthLayout />}>
          <Route index path='' element={<Main />} />
          <Route path='sign-up' element={<Phone />} />
          <Route path='sign-up/create-account' element={<Signup />} />
          <Route path='sign-in' element={<Signin />} />
          <Route path='reset-password' element={<ResetPassword />} />
        </Route>
      </Route>
      <Route path='/' element={<PrivateOutlet />}>
        <Route path='' element={<DashboardLayout />}>
          <Route path='explore' element={<Explore />} />
          <Route path='chat' element={<Chat />} />
          <Route path='following' element={<Following />} />
          <Route path='explore/search-finds' element={<SearchFinds />} />
          <Route path='my-jobs' element={<Myjobs />} />
          <Route path='notifications' element={<Notifications />} />
          <Route path='profile' element={<Profile />} />
          <Route path='make-profile/step-1' element={<ProfileAndLocation />} />
          <Route path='make-profile/step-2' element={<IntroVideo />} />
          <Route path='make-profile/step-3' element={<UploadDocuments />} />
          <Route path='make-profile/step-4' element={<Education />} />
          <Route path='create-video' element={<CreateVideo />} />
          <Route path='upload-or-record' element={<UploadOrRecord />} />
          <Route path='record-from-camera' element={<RecordVideo />} />
          <Route path='merge-videos' element={<MergeVideos />} />
          <Route path='other-job-seeker/:id' element={<UserProfile />} />
          <Route path='report' element={<Report />} />
          <Route path='other-employer/:id' element={<OtherEmployerProfile />} />
          <Route
            path='apply/upload-documents'
            element={<ApplyUploadDocuments />}
          />
          <Route path='apply/job-questions' element={<Questions />} />
          <Route path='apply/salery-range' element={<SaleryRange />} />
          <Route path='application-submit' element={<ApplicationSubmit />} />
          <Route path='settings' element={<Settings />} />
          <Route path='search-preferences' element={<SearchPreferences />} />
          <Route path='select-company' element={<SelectCompany />} />
          <Route path='create-company' element={<CreateCompany />} />
          <Route path='create-job' element={<CreateJob />} />
          <Route path='create-job/questions' element={<JobQuestions />} />
          <Route path='chat/schedule' element={<Schedular />} />
          <Route path='company-profile-making' element={<CompanyProfile />} />
          <Route path='premium' element={<Premium />} />
          <Route path='video-trimmer' element={<VideoTrimmer />} />
          <Route path='video-merge' element={<VideoMerge />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Index;

import React, { useState, useRef, useEffect, FC } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { googleApiKey } from 'app.config';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const LocationDialog: FC<IProps> = ({ open, setOpen }) => {
  const [location, setLocation] = useState({
    lng: -119.4179,
    lat: 36.7783,
  });
  const [inputValue, setInputValue] = useState('');
  const [predictions, setPredictions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const autocompleteService =
    useRef<google.maps.places.AutocompleteService | null>(null);

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey || '',
    libraries: ['places'],
  });

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (isLoaded && !autocompleteService.current) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
  }, [isLoaded]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (autocompleteService.current && e.target.value) {
      autocompleteService.current.getPlacePredictions(
        { input: e.target.value },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setPredictions(predictions);
          }
        }
      );
    } else {
      setPredictions([]);
    }
  };

  const handleSelectPrediction = (
    prediction: google.maps.places.AutocompletePrediction
  ) => {
    const placeId = prediction.place_id;
    const placesService = new window.google.maps.places.PlacesService(map!);

    placesService.getDetails({ placeId }, (place, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        place?.geometry?.location
      ) {
        // setLocation({
        //   lat: place.geometry.location.lat(),
        //   lng: place.geometry.location.lng(),
        // });
        // Extract city, state, and country from place.address_components
        const addressComponents = place.address_components;
        if (addressComponents) {
          const city =
            addressComponents.find(component =>
              component.types.includes('locality')
            )?.long_name || '';

          const state =
            addressComponents.find(component =>
              component.types.includes('administrative_area_level_1')
            )?.long_name || '';

          const country =
            addressComponents.find(component =>
              component.types.includes('country')
            )?.long_name || '';

          // setAddress({ city, state, country });
        }
      }
    });

    setInputValue(prediction.description);
    setPredictions([]);
  };

  return (
    <DialogLayout open={open}>
      <div className='w-full max-w-[689px] rounded-[16px] bg-white aspect-[1/0.88] min-h-[400px] flex flex-col overflow-hidden'>
        <div className='flex-grow relative'>
          {isLoaded && (
            <>
              <GoogleMap
                center={location}
                zoom={12}
                mapContainerStyle={{
                  width: '100%',
                  height: '100%',
                }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  restriction: {
                    latLngBounds: {
                      north: 49.38,
                      south: 24.52,
                      west: -125.0,
                      east: -66.93,
                    },
                    strictBounds: true,
                  },
                }}
                onLoad={map => setMap(map)}
              >
                <Marker
                  visible
                  position={{ lat: location.lat, lng: location.lng }}
                />
              </GoogleMap>
              <div className='search w-[calc(100%_-_80px)] absolute left-[50%] top-[27px] translate-x-[-50%]'>
                <div className='h-[50px]  border border-grey-400 flex items-center gap-2 bg-white relative rounded'>
                  <img
                    src={ASSETS.search}
                    className='absolute top-[50%] translate-y-[-50%] left-[15px]'
                    alt=''
                  />
                  <input
                    type='text'
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder='Search'
                    className='flex-grow outline-none pl-12 pr-4 h-full bg-transparent text-base'
                  />
                </div>
                {/* Custom Autocomplete Dropdown */}
                {predictions.length > 0 && (
                  <ul className='absolute w-full bg-white border border-gray-300 mt-1 rounded-md z-10 divide-y'>
                    {predictions.map(prediction => {
                      const [city, state] =
                        prediction.structured_formatting.main_text.split(',');
                      return (
                        <li
                          key={prediction.place_id}
                          className='cursor-pointer p-2 hover:bg-gray-100'
                          onClick={() => handleSelectPrediction(prediction)}
                        >
                          {city} {state}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
              <img
                src={ASSETS.mapButton}
                className='absolute right-0 bottom-0 rounded-full cursor-pointer'
                alt=''
                onClick={() => map?.panTo(location)}
              />
            </>
          )}
        </div>
        <div className='flex items-center gap-6 justify-center p-5 flex-shrink-0 min-h-[89px]'>
          <Button
            label='Cancel'
            variant='outlined'
            className='min-w-[213px] !text-base'
            onClick={handleClose}
          />
          <Button label='Confirm' className='min-w-[213px] !text-base' />
        </div>
      </div>
    </DialogLayout>
  );
};

export default LocationDialog;
